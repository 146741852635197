@import '_globals.less';

.expandable-v2 {
    position: relative;

    &.collapsed {
        height: 0;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }
}

.expandable-v2-toggle {
    cursor: pointer;
}